import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from 'components/Layout'
import SEO from 'components/SEO'
import { IHelpArticle } from 'utils/types'
import { UnknownRecord } from 'gatsby-source-prismic'
import ViewArticle from 'modules/ViewArticle/ViewArticle'

interface ArticleTemplateProps extends UnknownRecord<PropertyKey> {
  helpArticle: IHelpArticle
  allHelpArticle: {
    nodes: Pick<IHelpArticle, 'lang' | 'url'>[]
  }
}

const ArticleTemplate = ({ data }: PageProps<ArticleTemplateProps>) => {
  if (!data) return null

  const page = data.helpArticle
  const translationData = {
    lang: page.lang,
    url: page.url,
    translations: data.allHelpArticle.nodes,
  }

  return (
    <Layout translationData={translationData}>
      <SEO title={page.title} />
      <ViewArticle
        article={data.helpArticle}
        otherLanguages={data.allHelpArticle.nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  query articleQuery($id: String, $lang: String, $defaultLangUid: String) {
    helpArticle(id: { eq: $id }, lang: { eq: $lang }) {
      defaultLangUid
      title
      lang
      url
      content
      id
      related {
        link {
          document {
            ... on PrismicArticle {
              id
              data {
                title {
                  text
                }
              }
              url
            }
          }
        }
      }
    }

    allHelpArticle(
      filter: { defaultLangUid: { eq: $defaultLangUid }, lang: { ne: $lang } }
    ) {
      nodes {
        lang
        url
      }
    }
  }
`

export default withPrismicPreview(ArticleTemplate)
