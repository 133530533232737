import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { TopMenu } from './TopMenu'
import Footer from './Footer'
import { ITranslationData } from 'utils/types'

interface LayoutProps {
  children: React.ReactNode
  translationData: ITranslationData
}

export const Layout = ({ children, translationData }: LayoutProps) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{queryData.site.siteMetadata.title}</title>
        <meta
          name="description"
          content={queryData.site.siteMetadata.description}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <TopMenu translationData={translationData} />
      <main>{children}</main>
      <Footer translationData={translationData} />
    </>
  )
}
