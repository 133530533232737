import * as React from 'react'
import { Layout } from 'components/Layout'
import { graphql, PageProps } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { UnknownRecord } from 'gatsby-source-prismic'
import Homepage from 'modules/Homepage/Homepage'
import { linkResolver } from 'utils/helpers/linkResolver'
import { IHomePage } from 'utils/types'

interface HomepageTemplateProps extends UnknownRecord<PropertyKey> {
  prismicHome: IHomePage
}

const HomepageTemplate = ({ data }: PageProps<HomepageTemplateProps>) => {
  const homepage = data.prismicHome

  const translationData = {
    lang: homepage.lang,
    url: linkResolver(homepage),
    translations: homepage.alternate_languages.map((alternative) => ({
      lang: alternative.lang,
      url: linkResolver(alternative),
    })),
  }

  return (
    <Layout translationData={translationData}>
      <Homepage homepage={homepage} />
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHome(lang: { eq: $lang }) {
      lang
      alternate_languages {
        lang
        type
        uid
      }
      _previewable
      id
      type
      data {
        links {
          link {
            document {
              ... on PrismicArticle {
                id
                data {
                  title {
                    text
                  }
                  content {
                    text
                    html
                  }
                }
                lang
                uid
                url
              }
            }
          }
        }
        top_links {
          top_link {
            document {
              ... on PrismicArticle {
                id
                data {
                  content {
                    html
                    text
                  }
                  title {
                    text
                  }
                }
                lang
                uid
                url
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(HomepageTemplate)
