import React from 'react'
import getLanguageNameFromCode from 'utils/helpers/getLanguageName'
import { Link } from 'gatsby'
import { IHelpArticle } from 'utils/types'

interface ViewArticleProps {
  article: IHelpArticle
  otherLanguages: Pick<IHelpArticle, 'lang' | 'url'>[]
}

export default function ViewArticle({
  article,
  otherLanguages,
}: ViewArticleProps) {
  return (
    <div className="flex md:mt-16 mb-32 px-[5%] justify-center">
      <div className="flex flex-col space-x-0 space-y-16 lg:space-y-0 lg:space-x-24 lg:flex-row max-w-7xl w-full">
        <div className="">
          <h1 className="font-bold text-3xl md:text-4xl">{article.title}</h1>
          <div className="mt-8 article-content">
            <span dangerouslySetInnerHTML={{ __html: article.content }} />
          </div>
        </div>
        <div className="w-full lg:w-72 flex-shrink-0">
          <div className="">
            <h4 className="font-semibold">See in another language</h4>

            <div className="space-y-1 mt-2">
              {otherLanguages.map(({ lang, url }) => (
                <p key={lang}>
                  <Link className="text-gray-600 capitalize" to={url}>
                    {getLanguageNameFromCode(lang)}
                  </Link>
                </p>
              ))}
            </div>
          </div>

          {Boolean(article.related.length) && (
            <div className="mt-8">
              <h4 className="font-semibold">See also</h4>

              <div className="space-y-4 mt-2">
                {article.related.map(({ link }) => (
                  <Link
                    key={link.document?.id}
                    to={link.document?.url}
                    className="twoLineClamp text-lime-600 text-sm"
                  >
                    {link.document?.data.title.text}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
