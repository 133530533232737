import * as React from 'react'
import { navigate } from 'gatsby'
import { ITranslationData } from 'utils/types'
import getLanguageNameFromCode from 'utils/helpers/getLanguageName'

interface LanguageSwitcherProps {
  translationData: ITranslationData
}

export const LanguageSwitcher = ({
  translationData,
}: LanguageSwitcherProps) => {
  const { lang, url, translations } = translationData

  const handleLangChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(event.target.value)
  }

  return (
    <select
      className="capitalize w-20 md:w-full"
      value={url}
      onChange={handleLangChange}
    >
      <option value={url}>{getLanguageNameFromCode(lang)}</option>
      {translations.map(({ lang, url }) => (
        <option value={url} key={lang}>
          {getLanguageNameFromCode(lang)}
        </option>
      ))}
    </select>
  )
}
