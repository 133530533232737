import { ILanguage } from 'utils/types'

/** Generates full language from the locale code
 *  @param {ILanguage} languageCode
 * @returns {string} language name
 */
const getLanguageNameFromCode = (languageCode: ILanguage) => {
  const minifiedLanguageCode = languageCode.slice(0, 2)

  const languageNames = new Intl.DisplayNames([minifiedLanguageCode], {
    type: 'language',
  })

  return languageNames.of(minifiedLanguageCode)
}

export default getLanguageNameFromCode
