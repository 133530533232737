import React from 'react'
import { IHomePage } from 'utils/types'
import { BsSearch } from 'react-icons/bs'
import { Link, navigate } from 'gatsby'

interface HomepageProps {
  homepage: IHomePage
}

export default function Homepage({ homepage }: HomepageProps) {
  return (
    <div className="flex mb-32 px-[5%] justify-center">
      <div className="max-w-7xl w-full">
        <div className="flex items-center space-y-2 flex-col justify-center">
          <h1 className="text-3xl md:text-5xl text-center font-bold">
            Help Center
          </h1>
          <p className="text-base md:text-lg text-center text-gray-500">
            What do you want to know?
          </p>

          <div className="flex w-full pt-5 justify-center">
            <div className="w-full max-w-2xl relative">
              <input
                onFocus={() => navigate('/search')}
                className="h-12 w-full border px-4 rounded-lg text-center border-gray-200"
                placeholder="Search for something..."
                type="text"
                name="search"
                id="search"
              />
              <BsSearch className="absolute top-3.5 right-8 text-lg text-gray-600" />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {homepage.data.top_links.map(({ top_link }) => (
              <Link
                key={top_link.document.id}
                to={top_link.document.url}
                className="border hover:bg-gray-50 transition-all hover:shadow-md cursor-pointer border-gray-200 py-6 px-6 space-y-2 rounded-md"
              >
                <h2 className="font-bold twoLineClamp">
                  {top_link.document.data.title.text}
                </h2>
                <p className="threeLineClamp">
                  {top_link.document.data.content.text}
                </p>
              </Link>
            ))}
          </div>
        </div>

        <div className="mt-10 md:mt-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
            {homepage.data.links.map(({ link }) => (
              <Link
                key={link.document.id}
                to={link.document.url}
                className="border hover:bg-gray-50 hover:shadow-md cursor-pointer border-gray-200 p-5 lg:p-6 space-y-1 rounded-md"
              >
                <h3 className="font-semibold twoLineClamp">
                  {link.document.data.title.text}
                </h3>
                <p className="threeLineClamp text-sm">
                  {link.document.data.content.text}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
