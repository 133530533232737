import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { LanguageSwitcher } from './LanguageSwitcher'
import { ITranslationData } from 'utils/types'
import { HiOutlineSearch } from 'react-icons/hi'
import { IoMdMail } from 'react-icons/io'
import { MdTranslate } from 'react-icons/md'
import { linkResolver } from 'utils/helpers/linkResolver'

interface TopMenuProps {
  translationData: ITranslationData
}

export const TopMenu = ({ translationData }: TopMenuProps) => {
  const homepageLink = linkResolver({
    type: 'home',
    lang: translationData.lang,
    uid: '',
  })

  return (
    <header className="flex py-8 mb-6 px-[5%] justify-center bg-white">
      <div className="max-w-7xl w-full flex items-center justify-between space-x-3">
        <div className="flex space-x-0 md:space-x-3">
          <StaticImage
            width={36}
            className="hidden md:block"
            src="../images/logo.png"
            alt="logo"
          />
          <Link
            to={homepageLink}
            className="flex items-center space-x-1 font-bold"
          >
            <h3 className="text-emerald font-bold text-xl font-sans">
              Emerald
            </h3>
            <h3 className="text-xl text-gray-700">Help</h3>
          </Link>
        </div>

        <div className="flex items-center space-x-5 md:space-x-12 lg:space-x-20">
          <Link to="/search">
            <div className="flex items-center space-x-2">
              <HiOutlineSearch />
              <p className="hidden md:block">Search</p>
            </div>
          </Link>

          <a href="/support">
            <div className="flex text-gray-700 items-center space-x-2">
              <IoMdMail />
              <p className="hidden md:block">Support Ticket</p>
            </div>
          </a>

          <div className="flex text-gray-700 items-center space-x-0 md:space-x-2">
            {translationData && translationData.translations.length > 0 && (
                <>
                  <MdTranslate />
                  <LanguageSwitcher translationData={translationData} />
                </>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
