import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import { ITranslationData } from 'utils/types'
import { linkResolver } from 'utils/helpers/linkResolver'

interface FooterProps {
  translationData: ITranslationData
}

export default function Footer({ translationData }: FooterProps) {
  const homepageLink = linkResolver({
    type: 'home',
    lang: translationData.lang,
    uid: '',
  })

  return (
    <footer className="bg-darkGrey flex text-white pt-10 pb-16 px-[5%] justify-center">
      <div className="max-w-7xl w-full">
        <div className="hidden w-full sm:flex items-center">
          <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row w-full justify-around">
            <div className="space-y-2 flex flex-col items-start md:items-center justify-center">
              <Link to={homepageLink}>
                <StaticImage
                  width={120}
                  className="cursor-pointer"
                  src="../images/logo.png"
                  alt="logo"
                />
              </Link>
              <p className="text-white">
                {new Date().getFullYear()} © EmeraldPay, Inc
              </p>
            </div>

            <div className="space-y-4">
              <h4 className="font-semibold">HELP</h4>
              <div className="space-y-2">
                <p>
                  <Link to="/">Top Questions</Link>
                </p>

                <p>
                  <Link to="/search">Search</Link>
                </p>

                <p>
                  <a href="/support">
                    Support Ticket
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row w-full justify-around">
            <div className="space-y-4">
              <h4 className="font-semibold">EMERALD</h4>
              <div className="space-y-2">
                <p>
                  <a href="https://emerald.cash">Wallet Download</a>
                </p>
                <p>
                  <a href="https://emerald.cash">Home</a>
                </p>
                <p>
                  <a href="https://receipt.emerald.cash">Receipts</a>
                </p>
                <p>
                  <a href="https://blog.emerald.cash">Blog</a>
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h4 className="font-semibold">FIND US ONLINE</h4>
              <div className="space-y-2">
                <p>
                  <a href="https://twitter.com/getemerald">Twitter</a>
                </p>
                <p>
                  <a href="https://linkedin.com/company/emeraldpay">LinkedIn</a>
                </p>
                <p>
                  <a href="https://t.me/GetEmerald">Telegram</a>
                </p>
                <p>
                  <a href="https://github.com/emeraldpay">Github</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex sm:hidden flex-wrap justify-center items-center text-sm text-emerald space-x-4">
          <p className="mt-2">
            <Link to="/">Help</Link>
          </p>
          <a className="mt-2" href="https://emerald.cash">
            Project Home
          </a>
          <a className="mt-2" href="https://emerald.cash">
            Wallet
          </a>
        </div>

        <div className="flex flex-wrap mt-12 justify-center items-center text-sm text-gray-400 space-x-4">
          <a className="mt-2" href="https://emerald.cash/terms">
            Terms of Service
          </a>
          <a className="mt-2" href="https://emerald.cash/contacts">
            Contacts
          </a>
          <a className="mt-2" href="https://emerald.cash/security">
            Security
          </a>
          <a
            className="mt-2"
            href="/support"
          >
            Support Ticket
          </a>
        </div>
      </div>
      <CookieConsent
        disableStyles
        containerClasses="gdpr-cookie"
        buttonText="Accept"
        buttonWrapperClasses="flex justify-center space-x-7 mt-4"
        contentClasses="text-center"
        declineButtonText="Decline"
        enableDeclineButton
        buttonClasses="bg-emerald px-6 text-black font-semibold py-2 rounded-sm"
        declineButtonClasses="decline-button"
        cookieName="gdpr-ga"
      >
        We use cookies to provide the best site experience.
      </CookieConsent>
    </footer>
  )
}
