module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"emerald-help","accessToken":"MC5ZbjFCOWhNQUFDRUF1T2Zs.Xu-_ve-_ve-_vWInK--_ve-_vXxUYQl777-977-9OVAxZ0vvv73vv70777-977-977-977-9RlPvv71R","promptForAccessToken":true,"apiEndpoint":"https://emerald-help.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZQK3SMKWF6"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-152984804-1","cookieName":"gdpr-ga","anonymize":true},"googleTagManager":{"trackingId":"GTM-NJ5JH4C","cookieName":"gdpr-ga","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gdpr-fb"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"emerald-help","short_name":"help","start_url":"/","background_color":"#000","theme_color":"#00DC53","display":"minimal-ui","icon":"/opt/buildhome/repo/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"09128fc3bca13eab0e5fd70bfddac496"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
