/**
 * This file is used for setting up and connecting to Prismic.
 */

module.exports = {
  /** The domain name of the Prismic repository. */
  prismicRepo: 'emerald-help',

  /** The default language for content in your Prismic repository. */
  defaultLanguage: 'en-us',

  /** All alternate languages for content in your Prismic repository. */
  // 'de-de', 'pt-pt', 'uk', 'ru'
  alternateLanguages: [ 'es-es'],
}
